import { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from '@revolut/ui-kit'

import { useThemeMode } from 'hooks'
import { Routes } from './Routes'
import { IntlProvider } from './IntlProvider'

const features = { revolut9: true, revolut10: true }

export const AppProviders = ({ children }: { children?: ReactNode }) => {
  const themeMode = useThemeMode()

  return (
    <IntlProvider>
      <Provider mode={themeMode} features={features}>
        <BrowserRouter>{children}</BrowserRouter>
      </Provider>
    </IntlProvider>
  )
}

export const App = () => {
  return (
    <AppProviders>
      <Routes />
    </AppProviders>
  )
}
