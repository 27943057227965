import { ReactNode, useEffect, useState } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { DEFAULT_LOCALE, getCurrentLocale, getMessages, importMessages } from 'utils'

export const IntlProvider = ({ children }: { children: ReactNode }) => {
  const locale = getCurrentLocale()
  const [messages, setMessages] = useState<Record<string, string> | undefined>(() =>
    getMessages(locale),
  )

  useEffect(() => {
    importMessages(locale).then(setMessages)
  }, [locale])

  return (
    <ReactIntlProvider
      defaultLocale={DEFAULT_LOCALE}
      locale={locale.replace('_', '-')}
      messages={messages}
    >
      {children}
    </ReactIntlProvider>
  )
}
