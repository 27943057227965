import { FormattedMessage } from 'react-intl'
import { Header, Image, Layout } from '@revolut/ui-kit'

interface Props {
  logo: string
  children: React.ReactNode
}

export const WalletConnectLayout = ({ logo, children }: Props) => {
  return (
    <Layout>
      <Layout.Main>
        <Header variant="item">
          <Header.Title>
            <FormattedMessage
              id="web3.walletConnect.popup.title"
              defaultMessage="Connect your wallet"
            />
          </Header.Title>
          <Header.Subtitle>
            <FormattedMessage
              id="web3.walletConnect.popup.description"
              defaultMessage="Use WalletConnect to connect the wallet from which you want to import the NFT."
            />
          </Header.Subtitle>
        </Header>
        <Image m="auto" width={250} role="presentation" alt="WalletConnect" src={logo} />
      </Layout.Main>
      <Layout.Actions>{children}</Layout.Actions>
    </Layout>
  )
}
