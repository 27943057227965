import { useState } from 'react'
import { Web3Modal } from '@web3modal/react'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { captureException } from '@sentry/browser'
import { mainnet } from 'wagmi/chains'

import { getAssets } from 'api'
import { ErrorPopup } from 'components'
import { useThemeMode } from 'hooks'

import { PROJECT_ID, WALLET_CONNECT_LOGO_MAP } from './constants'
import { WalletConnectActions } from './WalletConnectActions'
import { WalletConnectLayout } from './WalletConnectLayout'

const chains = [mainnet]

const { publicClient } = configureChains(chains, [w3mProvider({ projectId: PROJECT_ID })])

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId: PROJECT_ID, chains }),
  publicClient,
})

const ethereumClient = new EthereumClient(wagmiConfig, chains)

export const WalletConnect = () => {
  const themeMode = useThemeMode()
  const [isErrorPopupOpen, setErrorPopupOpen] = useState(false)

  const handleSubmit = async (address: string) => {
    try {
      const data = await getAssets(address)
      const assets = JSON.stringify(data.assets)

      window.webkit?.messageHandlers?.addressHandler?.postMessage({ address, assets })
      window.android?.onAddress(JSON.stringify({ address, assets: data.assets }))
    } catch (error) {
      captureException(error)
      setErrorPopupOpen(true)
    }
  }

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <WalletConnectLayout logo={WALLET_CONNECT_LOGO_MAP[themeMode]}>
          <WalletConnectActions onSubmit={handleSubmit} />
        </WalletConnectLayout>
      </WagmiConfig>

      <Web3Modal
        ethereumClient={ethereumClient}
        themeMode={themeMode}
        projectId={PROJECT_ID}
      />

      <ErrorPopup open={isErrorPopupOpen} onClose={() => setErrorPopupOpen(false)} />
    </>
  )
}
