import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, VStack, Text } from '@revolut/ui-kit'
import { useWeb3Modal } from '@web3modal/react'
import { useAccount, useDisconnect } from 'wagmi'

interface Props {
  onSubmit: (address: string) => Promise<void>
}

const TRUNCATE_OFFSET = 6

const truncateAddress = (address: string) =>
  [address.slice(0, TRUNCATE_OFFSET), address.slice(-TRUNCATE_OFFSET)].join('...')

export const WalletConnectActions = ({ onSubmit }: Props) => {
  const { isOpen, open } = useWeb3Modal()
  const { disconnectAsync } = useDisconnect()

  const [isLoading, setLoading] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  const account = useAccount({
    onConnect: ({ address, isReconnected }) => {
      if (isReconnected || !address) return
      onSubmit(address)
    },
  })

  const handleConnect = async () => {
    setLoading(true)
    await open()
    setLoading(false)
  }

  const handleConnectNew = async () => {
    await disconnectAsync()
    await handleConnect()
  }

  const handleConnectExisting = async () => {
    if (!account.address) return
    setSubmitting(true)
    await onSubmit(account.address)
    setSubmitting(false)
  }

  if (account.address) {
    return (
      <VStack space="s-16">
        <Text variant="caption" textAlign="center">
          {truncateAddress(account.address)}
        </Text>
        <Button
          elevated
          disabled={isOpen || isSubmitting}
          pending={isSubmitting}
          onClick={handleConnectExisting}
        >
          <FormattedMessage
            id="web3.walletConnect.popup.action.continue"
            defaultMessage="Continue with connected wallet"
          />
        </Button>
        <Button elevated disabled={isOpen} variant="secondary" onClick={handleConnectNew}>
          <FormattedMessage
            id="web3.walletConnect.popup.action.connect_new_wallet"
            defaultMessage="Connect new wallet"
          />
        </Button>
      </VStack>
    )
  }

  return (
    <Button
      elevated
      disabled={isOpen}
      pending={isLoading || isOpen}
      onClick={handleConnect}
    >
      <FormattedMessage
        id="web3.walletConnect.popup.action.connect_wallet"
        defaultMessage="Connect wallet"
      />
    </Button>
  )
}
