import { FormattedMessage } from 'react-intl'
import { Button, StatusPopup } from '@revolut/ui-kit'

interface Props {
  open: boolean
  onClose: VoidFunction
}

export const ErrorPopup = ({ open, onClose }: Props) => {
  return (
    <StatusPopup variant="error" open={open}>
      <StatusPopup.Title>
        <FormattedMessage
          id="web3.errorPopup.title"
          defaultMessage="Something went wrong"
        />
      </StatusPopup.Title>
      <StatusPopup.Description>
        <FormattedMessage
          id="web3.errorPopup.subtitle"
          defaultMessage="Try again in a few minutes"
        />
      </StatusPopup.Description>
      <StatusPopup.Actions>
        <Button elevated onClick={onClose}>
          <FormattedMessage id="web3.errorPopup.button" defaultMessage="Got it" />
        </Button>
      </StatusPopup.Actions>
    </StatusPopup>
  )
}
