import localesEn from 'locales/en.json'

const SUPPORTED_LOCALES = [
  'bg',
  'cs',
  'da',
  'de',
  'el',
  'en_US',
  'en',
  'es',
  'fr',
  'hr',
  'hu',
  'it',
  'ja',
  'lt',
  'lv',
  'nb',
  'nl',
  'pl',
  'pt_BR',
  'pt',
  'ro',
  'ru',
  'sk',
  'sv',
]

const DEFAULT_MESSAGES = localesEn

export const DEFAULT_LOCALE = 'en'

export const getCurrentLocale = () => {
  if (process.env.NODE_ENV === 'test') return DEFAULT_LOCALE

  const locale = navigator.languages[0].replace('-', '_')

  // full match
  if (SUPPORTED_LOCALES.includes(locale)) return locale

  // language match for language_country
  const lang = locale.split('_')[0]

  if (SUPPORTED_LOCALES.includes(lang)) return lang

  return DEFAULT_LOCALE
}

const messagesMap = new Map([[DEFAULT_LOCALE, DEFAULT_MESSAGES]])

export const getMessages = (locale: string) => messagesMap.get(locale)

/* istanbul ignore next */
export const importMessages = (locale: string) =>
  import(
    /* webpackChunkName: "locale-[request]" */
    `locales/${locale}.json`
  )
    .then(messages => {
      messagesMap.set(locale, messages)
      return messages
    })
    .catch(() => DEFAULT_MESSAGES)
