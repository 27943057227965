const headers = {}

const createFetch =
  (method: RequestInit['method']) =>
  <T extends unknown>(url: string, body?: object) =>
    fetch(url, {
      method,
      headers,
      body: JSON.stringify(body),
    }).then(response => {
      if (!response.ok) throw new Error(String(response.status))

      return response.json() as Promise<T>
    })

export const post = createFetch('POST')
export const patch = createFetch('PATCH')
export const put = createFetch('PUT')
export const del = createFetch('DELETE')
export const get = createFetch('GET')
